@use 'common'

@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=50a914e5-9186-4b49-8f60-36d6a8c5e9f6")

@font-face
	font-family: "NeueVektor"
	font-weight: common.$fwNormal
	src: url("/fonts/58cec528-68e6-4864-b1a7-7dbee7d452fd.woff2") format("woff2"),url("/fonts/553a269b-03db-40d1-ab17-83eaf6362609.woff") format("woff")

@font-face
	font-family: "NeueVektor"
	font-weight: common.$fwBold
	src: url("/fonts/83b15a14-c534-4c13-9500-e6b8af6cb68f.woff2") format("woff2"),url("/fonts/56a24937-8f74-4669-a30d-034016bab543.woff") format("woff")

html,
body
	background: common.$goodlokLight
	color: common.$goodlokDark
	outline: none
	padding: 0
	margin: 0
	font-family: NeueVektor, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif

a
	color: inherit
	text-decoration: none

*
	box-sizing: border-box

ul
	list-style-type: none
	padding-left: 0

input
	font-family: inherit

button
	border: 0
	padding: common.$spacing*5
	cursor: pointer
	background: transparent
	font-family: inherit

// +common.debug()
